import React from 'react'
import ReactDOM from 'react-dom'
// import { useEffect, useState } from 'react'
 

class Popup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {name: ''}
  }
  handleChange (e) {
    e.preventDefault()
    var name = ReactDOM.findDOMNode(this.refs.name1).value.trim()
    var json1 = JSON.stringify({'name': name})

    name = ReactDOM.findDOMNode(this.refs.name2).value.trim()
    var json2 = JSON.stringify({'name': name})

    name = ReactDOM.findDOMNode(this.refs.name3).value.trim()
    var json3 = JSON.stringify({'name': name})

    var json = [json1, json2, json3]
    window.localStorage.setItem('city', json)
  }

  render () {
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <form>
            <input
              type='text'
              id="city_in"
              ref='name1'
              autocomplete="on"
              list="keywords"
              placeholder=" 一部を入力"
            />
            <button
              type='submit'
              onClick="save();"
            >
              選択して設定
            </button>
            <datalist id="keywords" >
              <option value="北海道札幌市" />
              <option value="北海道函館市" />
              <option value="北海道小樽市" />
              <option value="北海道旭川市" />
            </datalist>

            <details>
    <summary>2以上の自治体を選ぶ</summary>
            自治体２<input 
              type='text'
              ref='name2'
              autocomplete="on"
              list="keywords"
              placeholder=" 一部を入力"
              /><br />
            自治体３<input type='text' ref='name3' /><br />
            <button
              type='submit'
              onClick="save();">選択して設定</button>
            </details>
          </form>
        </div>
        <div>
        </div>
      </div>
      
    )
  }

}

export default Popup


{/*
function load() {
  var city = window.localStorage.city;
  if(city) {
    city = '<div id="selected_cityname">' + city + '</div>';
  } else {
    city = "福岡県糸島市";
  }
  document.getElementById("selected_cityname").innerHTML = city;

  if(city) {
    city = '<span id="selected_cityname_title">' + city + '</span>';
  } else {
    city = "福岡県糸島市";
  }
  document.getElementById("selected_cityname_title").innerHTML = city;

  function save() {
    var city = document.getElementById("city_in").value;
    window.localStorage.setItem("city", city);
    document.getElementById("selected_cityname").innerHTML = '<div id="selected_cityname">テスト</div>';
    self.location.reload()
}
}




{this.handleChange.bind(this)}

const Sample = () => {
   const [city, setName] = useState('')
   
   useEffect(() => {
       setName(localStorage.getItem('city'))
   })

   document.getElementById("selected_cityname").innerHTML = city;
  return (
    <div>Hello {city}</div>
    )
  */}

